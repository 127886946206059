import React, { useEffect } from "react"

import { motion, useAnimation } from "framer-motion";

import pageData from "content/info.yml"
import socialData from "content/settings_social.yml"

import Container from 'components/ui/layout/Container'
import Layout from 'components/ui/layout/Layout'

import bioImage from '../../images/mikell_about.jpg'

//import Image from 'components/ui/image/Image'

import classNames from 'classnames/bind'
import * as s from './infopage.module.scss'

const cn = classNames.bind(s)


// markup
const InfoPage = ({ children, transitionStatus, entry, exit, location }) => {

  let social = socialData.social_links.map(
    (socialItem, i) => {
      return (
        <h2 key={"social_" + i}>
          <a href={socialItem.social_link}>{socialItem.social_visible_text}</a>
        </h2>
      )
    }
  )

  return (
    <Layout
      transitionStatus={transitionStatus}
      entry={entry}
      exit={exit}
      isInfoPage={true}
      location={location}
    >

        <Container>
            <div className="about_bio">
              <p className="large">
                {pageData.bio}
              </p>
            </div>
            <div className={cn('about_photolockup')}>
              <div className={cn('about_social')}>
                {social}
              </div>
              <div className={cn('about_photo')}>
                <img src={bioImage} alt="Mikell Profile" />
              </div>
            </div>
        </Container>

  </Layout>
  )
}

export default InfoPage
